import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {isMobile} from 'react-device-detect';

const Splash = () => {
    const history = useHistory();

    return (
        <div className="intro">
            <video autoPlay muted loop id="intro-video" playsInline>
                <source src={isMobile ? "mobile.mp4" : "intro.mp4"} type="video/mp4" />
            </video>
            <div className="intro-row">
                <Col md="12">
                    <Image src="logo_2.png" className="logo" />
                    <Row className="mt-8">
                        <Col md={{ span: 2, offset: 5 }} xs={{ span: 10, offset: 1 }}>
                            <button className="d-block mx-auto button-styled" onClick={() => history.push("/portfolio")}>
                                ENTER
                            </button>
                        </Col>
                    </Row>
                </Col>
            </div>
        </div>
    );
};

export default Splash;

