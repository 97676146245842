import React from "react";
import { Col, Row, Image } from "react-bootstrap";

const About = () => {
    return (
        <Row>
            <Col md={{ span: 5, offset: 1 }}>
                <Image src="/me.jpg" fluid />
                <small>Photo by Julia Lutsyk</small>
            </Col>
            <Col md="5">
                <p>
                    Regina Foster is a professional fashion and lifestyle photographer based in Denver, Colorado, who frequently travels across the country for her work. She collaborates with the
                    modeling agency SWMT, assisting models in enhancing their portfolios. Her work has been featured on a Times Square billboard and has appeared in numerous publications.
                </p>
                <p>
                    Regina has had a deep love for fashion since childhood. As a young child, she would watch Fashion TV incessantly and kept fashion magazines in her drawer. Although she initially
                    pursued photography as a hobby in 2014, with a particular focus on food photography, she always knew that fashion was her true passion. Consequently, she decided to give it a try
                    and began photographing people during the Covid-19 pandemic. In 2022, she attended her first fashion show as a press photographer, marking the beginning of her involvement in the
                    fashion industry. Since then, Regina has covered multiple fashion shows, including Denver Fashion Week followed by New York Fashion Week.
                </p>
                <p>
                    Regina's photography style is highly distinctive, as she strives to capture the essence of life in every photograph she takes. She is receptive to new creative ideas and welcomes
                    collaborations with brands, magazines, and modeling agencies. Whether you are a local client based in Denver or located outside the state, Regina is dedicated to producing
                    exceptional work and is eager to work with you.
                </p>
            </Col>
        </Row>
    );
};

export default About;

