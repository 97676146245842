import React, { useCallback, useState } from "react";
import Gallery from "react-photo-masonry";
import Photo from "./Photo";
import Carousel, { Modal, ModalGateway } from "react-images";
import {isDesktop} from 'react-device-detect';

const Publications = () => {
    const photos = [
        { src: "/img/publications/img1.jpg", width: 853, height: 1280 },
        { src: "/img/publications/video.mp4", width: 1920, height: 1078 },
        { src: "/img/publications/img2.jpg", width: 2876, height: 1590 },
        { src: "/img/publications/img3.jpg", width: 2342, height: 1514 },
    ];

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <div>
            <Gallery margin={10} photos={photos} renderImage={isDesktop ? Photo : undefined} onClick={openLightbox} targetRowHeight={400} />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map((x) => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title,
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
};

export default Publications;

