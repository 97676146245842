import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-masonry";
import { useHistory, useParams } from "react-router-dom";
import photoData from "../config/photoData.json";
import Photo from "./Photo";
import {isDesktop} from 'react-device-detect';

const PhotoSet = () => {
    const { id } = useParams();
    const history = useHistory();

    const photos = photoData[id].data;
    const caption = photoData[id].caption;

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, []);

    return (
        <>
            <Row className="navbar-light mx-auto pl-2">
                <Col md="1" className="navbar-nav">
                    <p className="text-left nav-link" onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
                        {"< back"}
                    </p>
                </Col>
                <Col md="5"></Col>
                <Col md="6">
                    <h5 className="text-right">{caption}</h5>
                </Col>
            </Row>
            <div>
                <Gallery margin={10} photos={photos} renderImage={isDesktop ? Photo : undefined} onClick={openLightbox} targetRowHeight={photoData[id].target} />
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map((x) => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title,
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        </>
    );
};

export default PhotoSet;

