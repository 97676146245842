import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

library.add(fab, faAt);

const Header = () => {
    return (
        <Navbar variant="light" bg="white" expand="md" sticky="top">
            <Navbar.Brand>
                <Link to="/">
                    <img src="/logo.png" width="120" height="120" className="d-inline-block align-top" alt="React Bootstrap logo" />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar" />
            <Navbar.Collapse id="navbar" className="text-sm">
                <Nav className="ml-auto">
                    <Link to="/portfolio" className="nav-link mr-4">
                        PORTFOLIO
                    </Link>
                    <Link to="/about" className="nav-link mr-4">
                        ABOUT
                    </Link>
                    <Link to="/publications" className="nav-link mr-4">
                        PUBLICATIONS
                    </Link>
                    <span>
                        <Nav.Link href="https://www.instagram.com/reginafoster.us/" target="_blank" className="text-xl social">
                            <FontAwesomeIcon icon={["fab", "instagram"]} />
                        </Nav.Link>
                        <Nav.Link href="https://www.pinterest.com/reginafosterphoto" target="_blank" className="text-xl social">
                            <FontAwesomeIcon icon={["fab", "pinterest"]} />
                        </Nav.Link>
                        <Nav.Link href="mailto:mail@reginafoster.us" target="_blank" className="text-xl social">
                            <FontAwesomeIcon icon={["fa", "at"]} />
                        </Nav.Link>
                    </span>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;

