import React from "react";
import { Row, Col, Image } from "react-bootstrap";

const NotFound = () => {
    return (
        <>
            <Row>
                <Col md={{ span: 10, offset: 1 }}>
                    <Image src="/404.jpg" />
                </Col>
            </Row>
        </>
    );
};

export default NotFound;

