import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";

library.add(faHeart);
const Footer = () => {
  return (
    <p className="text-center mt-8 mb-2 text-sm">
      © Regina Foster Photography, 2024.{isMobile ? <br /> : ""} Created with{" "}
      <FontAwesomeIcon icon="heart" /> by{" "}
      <a href="mailto:mail@dennisfoster.us">Dennis Foster</a>
    </p>
  );
};

export default Footer;
