import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css";
import Header from "./components/Header";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import About from "./components/About";
import NotFound from "./components/NotFound";
import Footer from "./components/Footer";
import Portfolio from "./components/Portfolio";
import Splash from "./components/Splash";
import PhotoSet from "./components/PhotoSet";
import Publications from "./components/Publications";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/about">
                    <Container className="screen-height">
                        <Header />
                        <About />
                    </Container>
                    <Footer />
                </Route>
                <Route path="/portfolio">
                    <Container className="screen-height">
                        <Header />
                        <Portfolio />
                    </Container>
                    <Footer />
                </Route>
                <Route path="/publications">
                    <Container className="screen-height">
                        <Header />
                        <Publications />
                    </Container>
                    <Footer />
                </Route>
                <Route path="/gallery/:id">
                    <Container className="screen-height">
                        <Header />
                        <PhotoSet />
                    </Container>
                    <Footer />
                </Route>
                <Route exact path="/">
                    <Splash />
                </Route>
                <Route path="*">
                    <Container className="screen-height">
                        <Header />
                        <NotFound />
                    </Container>
                    <Footer />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;

