import React from "react";
import Gallery from "react-photo-masonry";
import { useHistory } from "react-router-dom";
import Photo from "./Photo";
import photoData from "../config/photoData.json";
import { isDesktop } from "react-device-detect";

const Portfolio = () => {
  const history = useHistory();
  const handleOnClick = (event, item) => {
    history.push("/gallery/" + item.photo.url);
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  };

  const photos = photoData.portfolio;

  // shuffleArray(photos);

  return (
    <Gallery
      photos={photos}
      renderImage={isDesktop ? Photo : undefined}
      margin={10}
      onClick={handleOnClick}
      targetRowHeight={400}
      direction="column"
      columns={3}
    />
  );
};

export default Portfolio;
